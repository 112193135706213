import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slideshow from "../components/Slideshow";

const imageList = [
  {
    src: "images/home-1.jpg",
    alt: "image1",
  },
  {
    src: "images/home-2.jpg",
    alt: "image2",
  },
  {
    src: "images/home-3.jpg",
    alt: "image3",
  },
  {
    src: "images/home-4.jpg",
    alt: "image4",
  },
  {
    src: "images/home-5.jpg",
    alt: "image5",
  },
  {
    src: "images/home-6.jpg",
    alt: "image6",
  },
  {
    src: "images/home-7.jpg",
    alt: "image7",
  },
  {
    src: "images/home-8.jpg",
    alt: "image8",
  },
  {
    src: "images/home-9.jpg",
    alt: "image9",
  },
  {
    src: "images/home-10.jpg",
    alt: "image10",
  },
];

const Home = () => {
  return (
    <div>
      <section className="flex items-center justify-center">
        <div className="absolute text-white z-10 text-4xl sm:text-5xl lg:text-8xl pl-6 font-bold tracking-wider text-center">
          <span>Ideas to </span>
          <br />
          <span className="text-lime-500">Implementation</span>
        </div>

        <img
          alt="mainImage"
          src="https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
          className="h-screen w-screen object-cover brightness-50"
        />
      </section>

      <section className="min-h-screen lg:px-12 px-6 grid mb-16 mt-32 lg:mt-0">
        <div className="grid lg:grid-cols-3 lg:gap-2 justify-center items-center grid-cols-1 gap-y-8">
          <div className="col-span-2 grid grid-col-1 gap-8">
            <p>
              Welcome to{" "}
              <span className="text-lg font-medium">i2i Consults</span>
            </p>
            <p>
              We are a{" "}
              <span className="text-lg text-lime-500 font-medium">
                Professional Consulting Organization
              </span>{" "}
              engaged in the social development arena across the globe.
            </p>
            <p>
              We have converted a wide range of{" "}
              <span className="font-semibold">ideas</span> that are evolved for
              the benefit of society into{" "}
              <span className="font-semibold">implementable</span> models. And
              implemented, monitored, and evaluated these models and made them
              replicable, scalable and sustainable.
            </p>
            <p>
              Since its inception in 2002, we have worked directly on many
              projects/programs and provided strategic, implementation
              consultancy to many clients.
            </p>
            <p>
              Currently, i2i Consults actively supports Healthcare Service
              Delivery, Maternal & Child Health & Nutrition intervention
              programs in India and Nigeria.
            </p>
            <p>
              Concurrently, we are also involved in many short & medium term
              projects on adolescent girl & women empowerment, enhancing rural
              livelihoods, water & sanitation, primary education, rural and
              urban development areas across many countries in{" "}
              <span className="inline font-semibold">Asia</span> and{" "}
              <span className="font-semibold">Africa</span>.
            </p>
          </div>

          <Slideshow imageList={imageList} className="brightness-90 rounded" />
        </div>
      </section>
    </div>
  );
};

export default Home;
