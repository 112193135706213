import React from 'react'

export type ProjectListItemProps = {
  ProjectName: string,
  Country: string,
  Activities: string,
}

export const ProjectListItem = (props:ProjectListItemProps) => {
  return (
    <div>
        <span>{props.ProjectName}</span> – <span className='font-semibold'>{props.Country}</span>
        <p className='font-light italic'>Key Activities: {props.Activities}</p>
        <hr />
    </div>
  )
}

// export {ProjectListItem, ProjectListItemProps}