import React, { useEffect } from "react";
import { HeaderLinksType } from "../types";
import Logo from "./Logo";
import NavLink from "./NavLink";

const links: HeaderLinksType[] = [
  {
    title: "Home",
  },
  {
    title: "Approach",
  },
  {
    title: "Services",
  },
  {
    title: "Specialization",
  },
  {
    title: "Projects",
    nestedNav: [
      {
        title: "Ongoing Projects",
      },
      {
        title: "Completed Projects",
      },
    ],
  },
  {
    title: "Clients",
  },
  {
    title: "Capacity",
    nestedNav: [
      {
        title: "Advisory Committee",
      },
      {
        title: "Team",
      },
      {
        title: "Partners",
      },
    ],
  },
];

const Header = () => {
  const [showNavbar, setShowNavbar] = React.useState(false);
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize > 1022) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  }, [windowSize]);

  return (
    <nav className="backdrop-blur-sm bg-white/90 px-8 w-full lg:flex justify-between border-black align-middle shadow-md rounded text-sm">
      <div className="p-2 "><Logo size={48}/></div>
      <div className="absolute right-6 top-4 block lg:hidden">
        <button
          className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600"
          onClick={() => setShowNavbar(!showNavbar)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      {showNavbar && (
        <ul className="flex lg:flex-row flex-col gap-x-8 text-center items-center justify-stretch lg:divide-y-0 divide-y divide-gray-300">
          {links.map((link, index) => (
            <NavLink key={index} link={link} />
          ))}
        </ul>
      )}
    </nav>
  );
};

export default Header;
