import React from 'react'

type AdvisoryMemberProps = {
    name: string,
    body: string,
}

const AdvisoryMember = (props:AdvisoryMemberProps) => {
    return (
        <div>
            <h2 className='font-semibold text-xl'>{props.name}</h2>
            <p>{props.body}</p>
        </div>
    )
}

const members: AdvisoryMemberProps[] = [
    {
        name: "Mike Egboh",
        body: "A strategic leader with over 25 years of experience in planning and management of programs, health systems strengthening, population and development, child survival, sexual and reproductive health and gender and human rights. Fundraised and managed major donor-funded projects. Reputed for improving program portfolios, breaking cultural and religious barriers for effective health outcomes, initiating policy dialogues and advocating for change. Recognized as “a relentless innovator in thought and action” by the Nigerian Television Authority."
    },
    {
        name: "Pharm. Ahmed I Yakasai",
        body: "Ahmed I Yakasai is an accomplished Pharmacist/independent Consultant with comprehensive background in International Marketing. He is a Fellow of Pharmaceutical Society of Nigeria, Fellow of Institute of Logistics Management of Nigeria, Fellow of Chartered Institute of Commerce, Fellow of Nigeria Academy of Pharmacy, and Fellow of Nigerian Institute of Management. He has worked variously in both public and private sectors: Two terms Commissioner in Kano State. First, he was Commissioner of Commerce, Industry, Cooperatives and Tourism and was redeployed to the Ministry of Land and Physical Planning. He worked in four government hospitals before joining private sector in 1987 and later in 1992 to set up Pharmaplus Nigeria Limited as the founder and Managing Director/CEO till date. Ahmed Yakasai is a member of numerous Professional, Academic and Social organizations. He holds the Igbo traditional title of EZI ENYI DIORANMA OF UMUZIKE1, ORLU, because of his selfless service to mankind. Ahmed has also earned enviable honours from so many organizations."
    },
    {
        name: "Hugh Annet",
        body: "Hugh Annett is an Associate Consultant of OPM, technical lead for the ORIE qualitative impact evaluation, and chair of the OPM Ethics Review Committee; Visiting Professor, Faculty of Health and Life Sciences, University of the West of England; and was a member of the Appeal Committee of the National Institute for Health and Clinical Excellence (NICE) until he retired from the NHS at the end of 2012. Prior to re-entering the NHS in 2002 he was Head of the Health Department of the Secretariat of the Aga Khan with responsibility for one of the largest non-profit private health care systems in Africa and Asia. Previously he was Head of the International Health Division at the Liverpool School of Tropical Medicine, which became a WHO Collaborating Centre for Health Systems Development during his tenure. As Director of Public Health for Bristol he was responsible for public health policy and strategy, and for providing strategic leadership for reducing health inequalities while improving the health of the population."
    }
];

const Advisory = () => {
  return (
    <div className='flex flex-col mt-32 mx-16 space-y-8 mb-16'>
        {members.map(element => 
            <AdvisoryMember name={element.name} body={element.body} />
          )
        }
    </div>
  )
}

export default Advisory