import React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { HeaderLinksType } from "../types";

const NavLink = (props: { link: HeaderLinksType }) => {
  const location = useLocation();
  const [showNestedNav, setShowNestedNav] = React.useState(false);
  return (
    <div
      onMouseOver={() => setShowNestedNav(true)}
      onMouseLeave={() => setShowNestedNav(false)} className="relative w-full">
      <Link
        to={`/${props.link.title.toLowerCase()}`}
        className={clsx("p-2 hover:text-lime-600 text-md", {
          "text-lime-600":
            location.pathname === `/${props.link.title.toLowerCase()}`,
        })}
      >
        {props.link.title}
      </Link>

      {props.link.nestedNav && showNestedNav && (
        <div className="absolute w-48 top-6 right-0  z-10">
          <div className="flex flex-col bg-white/90 shadow-md rounded">
            {props.link.nestedNav.map((nestedLink) => (
              <Link
                to={`/${nestedLink.title.split(" ")[0].toLowerCase()}`}
                className="p-2 hover:text-lime-600 text-md"
              >
                {nestedLink.title}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavLink;
