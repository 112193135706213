import React from "react";
import TeamMember from "../components/TeamMember";
import { TeamMemberProps } from "../types";

const members: TeamMemberProps[] = [
  {
    name: "Geeta Pant",
    position: "Managing Director",
    body: "She has a Masters Degree in Organic Chemistry and an additional degree of Bachelor of Education. She has been the brain and inspiration behind the success of i2i Consults. She has been the Managing Director of i2i Consults since its inception. She has also been involved in setting up and running other successful organization like Adrem Advertising and Marketing Services Pvt. Ltd and Achieverz Classes. She is an entrepreneur par excellence and besides being MD also heads the finance division of i2i Consults. She has a keen interest in solving the problem faced by the poor and oversees the CSR work of Achieverz classes to provide free education to the poor students",
    img: "images/team-geeta.jpg",
  },
  {
    name: "Vimal Kumar",
    position: "Principal Consultant & CEO",
    body: "An MBA having more than 27 years experience in Development and Corporate Sectors. An expert in Project Management, Procurement and Supply chain management, Capacity building, Monitoring and evaluation, Brand and Sales Management areas. He has substantial experience in working with many international organizations such as DFID, UNOPS, PATHS, Global Fund, NACA, JSI/Deliver, etc.",
    img: "images/team-vimal.jpg",
  },
  {
    name: "K S Murthy",
    position: "Principal Consultant & Technical Director",
    body: "A Post Graduate in Management from the Indian Institute of Management, Ahmadabad, having more than 20 years experience in Social Development Sector as Entrepreneur, Team Leader, Manager and Independent Consultant. He held senior positions as CEO/COO in International Development Organizations and managed diverse projects on Health Sector Reforms, Health & Family Welfare, Maternal & Child Health, Nutrition, Rural Development, Livelihood funded by various donor organizations in many countries in Asia and Africa. He carried out procurement, supply chain management systems, monitoring & evaluation assessments on public health departments of various national and provincial Governments liaising with top national level bureaucracy and extensively interacting with officials of International Donor Agencies.",
    img: "images/team-murthy.jpg",
  },
  {
    name: "Douglas Guest",
    position: "Senior Consultant – Health Commodities",
    body: "A Logistics and Supply Chain Management expert with more than 40 years experience. He specializes in Automotive and Health logistics with focus on “total” logistics management, inventory control, warehousing and distribution under “difficult” conditions.  Has a strong track record of transferring commercial ‘best practices’ and quality standards to operations in humanitarian/development environments. Has an Extensive practical experience of managing international supply chains to service end-users in a number of East, West and Central African countries.",
    img: "",
  },
  {
    name: "B S Sudish",
    position:
      "Senior Consultant – Project Management, M&E, Econometrics & Data Analytics",
    body: "A project management professional (PMP), with an economics and financial services background (with emphasis on econometrics), brings-in rich data analytics experience across domains such as social sector, retail & CPG, pharma, life sciences and financial services. He has more than 20 years of experience in the consumer and business analytics, have developed solutions across entire customer intelligence portfolio starting from data strategy and management through insights to operationalization of insights.",
    img: "",
  },
  {
    name: "Pharm. Mohmood Al'Jafreh",
    position: "Senior Consultant – Health",
    body: "An international Medical Supply Chain expert with 25+ years of experience in working and managing supply chain systems. He is providing Technical Assistance in system assessment, design and implementation with special focus on Pharmaceuticals. Al-ja’freh has extensive work experience in the public, private, and NGO’s healthcare system development, functioning and strengthening. He hands on work experience with international firms like Axios foundation, John Snow Inc., and Abt. Associates and has advanced managerial, strategic planning, interpersonal communication and partnering skills. He has also served in military and gained skills of working under pressure, precision and time management, self-dependence, problem solving and informed decision making.",
    img: "images/team-mohmood.jpg",
  },
  {
    name: "Pharm. Auwalu Abusalam",
    position: "Senior Consultant – Health",
    body: "A professional pharmacist, who has worked for over 8 years for DFID sponsored programmes in the Logistics and supply chain management systems (SCMS) of health commodities. He was involved in Systems strengthening of sustainable drug supply systems (SDSS). Areas of coverage included Policies, systems and SOPs to improve SDSS. He has expertise in procurement planning, Logistics Management Information System, distribution including warehousing of health commodities in the health sector. Auwalu Abdulsalam is a party to the successful commencement and continuity of the PATHS2 supported DRF scheme in Kano State of Nigeria which has supported the dramatic improvement in the health status of the population. He retired as Director Pharmaceutical Services in Kano State.",
    img: "",
  },
  {
    name: "Naser Ahmad Khan",
    position: "Senior Consultant – Water & Sanitation",
    body: "He has more than 20 years of experience on Water and sanitation related issues in national and international organizations. In water and Sanitation, he has been involved in Project Proposal Development, Fund Raising and Project Management, Organizing Training Programs for implementing agencies and technical teams, Monitoring and evaluation of developmental programs and projects, Community Development and community capacity building, Capacity building and support to Local Governments., State Governments and Local NGOs.",
    img: "",
  },
  {
    name: "Pharm. Achan James Angi",
    position: "Senior Consultant – Health",
    body: "A development professional with over eight (8) years experience in health programs within Nigeria focused on health commodity (Drugs including vaccines and related materials, hospital diagnostics consumables, reagents and equipment) supply chain management and health systems strengthening. Achan is skilled in program planning and management for the achievement of desired health outcomes. He has managed the establishment and operationalization of both drugs (Drug Revolving Fund) and diagnostics (Diagnostics Consumables Revolving Fund) revolving systems in over 400 health facilities in Kaduna Nigeria. He is a qualified Project Manager, Logistics specialist and trainer in health commodity and related systems management and has advanced skills in Pharmacology, business administration and public health functions. Achan has competencies in policy and law development, implementation and management for the achievement of the policy/Law stated mandates, strategic planning and program management including capacity building, immunization activities, health systems strengthening and health services delivery.",
    img: "",
  },
  {
    name: "Manu Pant",
    position: "Senior Consultant – Education",
    body: "Has more than 12 years of experience in Corporate, Health & Social Development sectors. Involved in various development projects with many international organizations such as DFID, UNOPS, JSI/Deliver, Johnson& Johnson, Hindustan Unilever, Vardhman Textiles etc. He is a specialist in the area of Procurement, Ware Housing and inventory management, Distribution, Project Auditing, Monitoring and Evaluation.",
    img: "",
  },
  {
    name: "Piyush Sharma",
    position: "Finance Advisor",
    body: "A Finance professional, with more than 20 years of experience in operations finance, administration, legal, secretarial, and commercial functions. He has worked with various national and international organizations as CFO. His experience includes 12 years in Software/ IT/ Telecom industry and 6 years in Tyres & FMCG.",
    img: "",
  },
  {
    name: "Godiya Enzo",
    position: "Senior Consultant – Voice & Accountability",
    body: "Godiya Enjo has good working and consultancy experiences with a range of partners and clients that includes international agencies, national government, the private sector, and civil society. With background in sociology and post graduate study in Public Policy Analysis, he has worked with MDAs, implementing/development partners, CSOs and other stakeholders at all levels across the country implementing numerous interventions especially in the heath sector. He’s a facilitator/trainer and mobilizer with excellent soft and communication skills; a team player with a keen focus on exceeding set objectives or target. He's self-motivated and can work independently.",
    img: "",
  },
];

const Team = () => {
  return (
    <div className="pt-28 px-16 pb-16">
      {members.map((member) => (
        <TeamMember
          name={member.name}
          position={member.position}
          body={member.body}
          img={member.img}
        />
      ))}
    </div>
  );
};
export default Team;
