import React from 'react'

const partnerList : string[] = [
    'Pharma Plus Pharmaceuticals Limited',
    'Annunciation Specialist Hospital',
    'Nuzon Logistics',
    'Health Systems Consults Limited',
    'VFM Logistics Ltd, UK',
    'Achieverz Classes',
    'SALAAHkars (Society for Actualizing Livelihood Assistance And Help)',
    'SNEH (Society for Nature, Education and Health)',
    'Adrem Advertising & Marketing Services Pvt. Ltd',
]

const Partners = () => {
    return (
        <div className='mt-32 mx-16 mb-16 flex flex-col space-y-8'>
            <p>i2i Consults have formed strategic partnerships with key organizations operating in various parts of the world to bring additional expertise and services to our clients.</p>
            <p>We are in an ever-expanding mode within existing and newer realms, and therefore always looking to get associates from related areas to provide wider range of solutions for our Clients. Please contact us at <a href='mailto:info@i2iconsults.com' className='text-lime-500 underline'>info@i2iconsults.com</a> to find out how yours and our organizations could possibly work together for the benefit of the Clients.</p>
            <ul className='list-disc'>
                {partnerList.map(element => 
                    <li>{element}</li>
                )}
            </ul>
        </div>
    )
}

export default Partners