import React from 'react'
import FooterLink from './FooterLink';

const links = [
    "Home",
    "Approach",
    "Services",
    "Specialization",
    "Projects",
    "Clients",
    "Capacity",
  ];

const Footer = () => {
  return (
    <div>
        <footer>
        <div className="grid lg:grid-cols-3 grid-cols-1 bg-gray-100 p-6">
          <div className="lg:col-span-2">
            <h1 className="text-xl font-semibold tracking-wider">
              i2i Consults
            </h1>
            <div className="grid grid-cols-2 gap-4 text-sm p-4">
              {links.map((link, index) => (
                <FooterLink to={link} key={index} />
              ))}
            </div>
          </div>
          <div className="grid grid-col-1 gap-y-4">
            <h1 className="text-xl font-semibold tracking-wider">Contact Us</h1>
            <div className="text-xs text-gray-500">
              <span className="font-semibold">India Office : </span> <br />
              11/128, Vasundhara,
              <br /> Ghaziabad U.P.- 201012 INDIA
              <br /> <span className="font-semibold">Tel: </span> +91 9910741580
            </div>
            <div className="text-xs text-gray-500">
              <span className="font-semibold">Nigeria Office : </span> <br />
              House No 415, A Close
              <br /> First Avenue, Gwarinpa State
              <br /> Abuja, Nigeria
              <br /> <span className="font-semibold">Tel: </span> +234
              8038585359
            </div>
          </div>
        </div>
        <div className="col-span-3 text-sm bg-gray-200 text-gray-500 p-6">
          &#169;2022 i2i Consults. All rights reserved.
        </div>
      </footer>
    </div>
  )
}

export default Footer;
