import React from 'react'

const Approach = () => {
  return (
    <div className='flex flex-col mt-32 lg:px-12 gap-y-8 mb-16'>
        <p>
            We always work closely with local governments at various levels and all other key stakeholders in a participatory manner for all interventions / projects / programs and strongly believe that participatory approach, though challenging in the short-term, will lead to robust results.
        </p>
        <p>
            i2i Consults has extensive experience in building partnerships between International Donor Organizations, Development Trusts, Private Sector, Community Based Organizations & NGOs.
        </p>
        <h1 className='text-3xl font-semibold'>For All Our Assignments, We</h1>
        <ul className='list-disc mx-16'>
            <li>Carry out extensive <span className='font-semibold'>need assessments</span> and derive critical problem.</li>
            <li>Design & test alternate methods and processes required for sustainably addressing these problems.</li>
            <li>Plan & Execute various tasks (both at <span className='font-semibold'>micro</span> and <span className='font-semibold'>macro</span> levels) required to solve specific issues.</li>
            <li><span className='font-semibold'>Document</span> the processes & results and <span className='font-semibold'>replicate</span> the solutions that influence the desired outcomes and impacts.</li>
        </ul>
        <p>Our endeavor is to provide integrated, <span className='font-semibold'>tailor-made</span> and flexible solutions to our clients & programs and to ensure that required resources are delivered in the right quantity at right time and in a cost effective way.</p>
        <h1 className='text-3xl font-semibold'>Process</h1>
        <img src='process.png' alt='process' />
        <h1 className='text-3xl font-semibold'>Innovations</h1>
        <p>i2i Consult’s have implemented many innovative muti-sectoral projects involving large corporate houses, government departments, international development organizations, local community based organizations and communities. These innovative sustainable and replicable/scalable programs that easily integrate into larger governmental programs have positively impacted wide populations</p>
        <p>We have facilitated integration of domain expertise, skilled manpower and proprietary processes & techniques from corporate with grassroots implementation skills of local NGOs, social development methodologies derived from international organizations and linking them with the infrastructure and policy support of the government.</p>
    </div>
  )
}

export default Approach