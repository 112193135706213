import React from "react";
import { TeamMemberProps } from "../types";

const TeamMember = (props: TeamMemberProps) => {
  return (
    <div className="flex md:flex-row items-center flex-col gap-6 p-4 my-4 bg-white rounded-lg drop-shadow-md">
      {props.img && (
        <img className="w-32 border rounded-lg object-cover" src={props.img} alt={props.name} />
      )}
      <div>
        <h3 className="text-lime-500 font-semibold tracking-wider text-2xl">
          {props.name}
        </h3>
        <h4 className="text-sm text-gray-500 pb-4 font-semibold">({props.position})</h4>
        <p className="text-sm">{props.body}</p>
      </div>
    </div>
  );
};

export default TeamMember;
