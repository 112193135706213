import React from "react";
import { Carousel } from "react-responsive-carousel";

const Slideshow = (props: {
  imageList: Array<{
    src: string;
    alt: string;
  }>;
  className?: string;
}) => {
  return (
    <Carousel
      showIndicators={false}
      showStatus={false}
      infiniteLoop
      autoPlay
      showThumbs={false}
    >
      {props.imageList.map((image, index) => (
        <img
          key={index}
          className={props.className}
          src={image.src}
          alt={image.alt}
        />
      ))}
    </Carousel>
  );
};

export default Slideshow;
