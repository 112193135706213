import React from 'react'
import { ProjectListItem, ProjectListItemProps } from '../components/ProjectListItem';

const projects: ProjectListItemProps[] = [
  {
    ProjectName: "DFID (Department for International Development) / Health Commodities Project",
    Country: "Nigeria",
    Activities: "Strengthening Policy, Systems and Institutions"
  },
  {
    ProjectName: "GFATM (Global Fund to Fight HIV/AIDS, TB & Malaria) / United Nations Organisation for Project Support (UNOPS) / Ministry of Health - Chinese Centre for Disease Control & Prevention - For Control of Malaria",
    Country: "China",
    Activities: "Assessment of Procurement and Supply Management"
  },
  {
    ProjectName: "GFATM (Global Fund to Fight HIV/AIDS, TB & Malaria) / United Nations Organisation for Project Support (UNOPS) / Ministry of Health - Chinese Centre for Disease Control & Prevention - China Comprehensive AIDS Response (China CARES)",
    Country: "China",
    Activities: "Assessment of Procurement and Supply Management"
  },
  {
    ProjectName: "UNOPS (United Nations Organisation for Project Support) / NACO (National Aids Control Organisation), Ministry of Health & Family Welfare (MoH&FW)",
    Country: "India",
    Activities: "Assessment and systems development for Procurement and Supply Chain Management"
  },
  {
    ProjectName: "The World Bank / Uttar Pradesh Health Systems Development Project (UPHSDP)",
    Country: "Government of Uttar Pradesh, India",
    Activities: "Development of Project Implementation Plan"
  },
  {
    ProjectName: "The World Bank / Department of Medical & Health, Government of Uttar Pradesh",
    Country: "Government of Uttar Pradesh, India",
    Activities: "Baseline Survey, Beneficiary Needs Assessment, Private Sector Study for Uttar Pradesh Health Systems Development Project"
  },
  {
    ProjectName: "The World Bank / Department of Medical & Health",
    Country: "Government of Uttar Pradesh - India",
    Activities: "Feasibility Study & Project Implementation Plan for improving the status of Primary & Secondary Health Facilities and bringing out policy reforms covering various aspects of Hospital Management, Health Management Information System, Disease Surveillance, Referral services, procurement and management of Civil constructions and hospital equipment, Procurement, storage & distribution of Drugs & Hospital Supplies, Hospital Waste Management, Quality Assurance & Information Education & Communication"
  },
  {
    ProjectName: "The World Bank / Ministry of Health & Family Welfare, Government of Uttaranchal, Uttaranchal Health Systems Development Project (UAHSDP)",
    Country: "India",
    Activities: "Revising Human Resource Strategies for Health Systems Strengthening"
  },
  {
    ProjectName: "The World Bank / State Government of Uttaranchal / Uttaranchal Health Systems Development Project (UAHSDP)",
    Country: "India",
    Activities: "Technical & Managerial assistance to the State Strategic Management Board & Strategic Support Group"
  },
  {
    ProjectName: "The World Bank / UPHSDP – Department of Medical & Health, Government of Uttar Pradesh",
    Country: "India",
    Activities: "Development of Standard Treatment Guidelines, Essential Drug List, Estimating Drugs Requirement, Procurement & Rationalization of Supplies in Public Health Facilities",
  },
  {
    ProjectName: "The World Bank / Karnataka Health Systems Development Project (KHSDP), Department of Health & Family Welfare, Government of Karnataka",
    Country: "India",
    Activities: "Providing Project Management Support & Development of Management information Systems.",
  },
  {
    ProjectName: "JSI/Deliver / Ministry of Health & Family Welfare, Government of Uttaranchal",
    Country: "India",
    Activities: "Develop Procurement Policy and Procurement Manual. Prepare action plan for implementation. Prepare Strategic Plan for Logistics Management",
  },
  {
    ProjectName: "MoH&FW / Department of Indian Medicine & Homeopathy",
    Country: "India",
    Activities: "Formulate strategy for integration of Alternative Health Care System like Ayurveda, Unani, and Homeopathy.",
  },
  {
    ProjectName: "World Health Organisation (WHO) / Management Sciences for Health (MSH), USA / MoH&FW",
    Country: "India",
    Activities: "TB Drugs – Sector Assessment Study",
  },
  {
    ProjectName: "DFID (Department for International Development) / CARE / Shram Shakti (AIDS awareness project for Industrial Workers)",
    Country: "India",
    Activities: "Managing the Rapid Appraisal Studies",
  },
  {
    ProjectName: "DFID (Department for International Development) / CARE / OKTATA (Orientation of Key Truckers and Transporters on AIDS) project",
    Country: "India",
    Activities: "Managing the Rapid Appraisal Studies",
  },
  {
    ProjectName: "CIDA (Canadian International Development Agency) / CARE / Maternal & Infant Survival Project",
    Country: "India",
    Activities: "Designing and implementing the monitoring system",
  },
  {
    ProjectName: "World Food Program (WFP) / CARE / Health and Nutrition Project",
    Country: "India",
    Activities: "Designing and implementing the monitoring system",
  },
  {
    ProjectName: "UNICEF / National Survey of Rural Community Water Supplies (NSRCWS)",
    Country: "India",
    Activities: "A water resource survey covering rural areas of nine states accounting for 74 percent of India’s population and 80 percent of deep-well-hand-pumps installed in the country",
  },
  {
    ProjectName: "The World Bank / Integrated Child Development Program (ICDS-III)",
    Country: "India",
    Activities: "Review and correct the existing mechanism of Distribution of Nutrition, its quality, satisfaction of beneficiaries and need for alternative cost-effective system",
  },
  {
    ProjectName: "UNICEF/ Ministry of Education, Government of India / State Institute of Educational, Management and Training (SIEMAT, UP) – Uttar Pradesh",
    Country: "India",
    Activities: "Study the family effects on enrollment, retention and achievement of students in Government Schools, Study the present mechanism/system of enrolment and identify the strength and weakness of the present system",
  },
  {
    ProjectName: "CARE India / Health, Nutrition & Population Division",
    Country: "India",
    Activities: "Baseline Survey of Agra Reproductive Health Project - Prevention of Reproductive Tract Infections (RTI), Sexually Transmitted Diseases (STD) and HIV/AIDS in Commercial Sex Workers",
  },
  {
    ProjectName: "United States Aid for International Development (USAID) /AED / CARE/Linkages Project / Government of India",
    Country: "India",
    Activities: "Final Evaluations of Integrated Nutrition and Health Program (one of the largest nutrition sector projects in the world jointly funded by USAID and Government of India), and CARE/Linkages project.",
  },
  {
    ProjectName: "CARE India's Integrated Nutrition and Health Program (INHP)",
    Country: "India",
    Activities: "Assessment of Logistics and Distribution of Nutrition supplies from port to district warehouses to block warehouses to AWCs of ICDS in 9 States",
  },
  {
    ProjectName: "Taj Group of Hotels / Integrated Child Development Services (ICDS) / State Health & Nutrition Mission, Maharashtra",
    Country: "India",
    Activities: "Diversifying and improving quality & hygiene of supplementary food at Anganwadi Centers (AWCs)",
  },
  {
    ProjectName: "UNICEF / Hindustan UNILEVER / Ogilvy Action / Government of Maharashtra",
    Country: "India",
    Activities: "Behavioural Change Communications on personal hygiene through Hand Washing.",
  },
  {
    ProjectName: "Tata Consultancy Services / Department of Tribal Welfare, Government of Maharashtra",
    Country: "India",
    Activities: "Improving Adult literacy and Building Awareness on Health & Nutrition amongst Tribal Women through Computer Aided Program."
  },
  {
    ProjectName: "NIKE Foundation / Synergos Institute, New York / UNICEF / Departments of Tribal Welfare, Health & Family Welfare, ICDS, and State Health & Nutrition Mission, Government of Maharashtra",
    Country: "India",
    Activities: "Designing, Executing, Monitoring & Evaluation and Scaling of Project for empowering adolescent girls on health & nutrition (A partnership project with local NGOs)",
  },
  {
    ProjectName: "Hindustan Unilever / UNICEF / State Health & Nutrition Mission, Government of Maharashtra",
    Country: "India",
    Activities: "Designing, Executing, Monitoring & Evaluation and Scaling of Project on Behaviour Change Communications from Complementary Nutrition for children between 6-12 months",
  },
  {
    ProjectName: "Department of Health and Family Welfare, Government of Maharashtra / Hindustan Unilever",
    Country: "India",
    Activities: "Improving Supply Chain of Medicines & Vaccines at Government Health Facilities.",
  },
]

const Projects = () => {
  return (
    <div className='pt-32 px-16 flex flex-col space-y-8 pb-16'>
        <p className='text-md'>Since inception in 2002 , i2i Consults and its principal consultants have worked on many projects in different sectors across the globe.</p>
        <h1 className='text-3xl'>Notable Mentions</h1>
        {projects.map(element => 
            <ProjectListItem ProjectName={element.ProjectName} Country={element.Country} Activities={element.Activities} />
          )
        }
    </div>
  )
}

export default Projects