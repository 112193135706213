import React from 'react'

const Services = () => {
  return (
    <div className='mt-32 mb-16 mx-16'>
        <h1 className='text-2xl font-semibold'>We <span className='font-bold'>Excel</span> At Providing The Following Services</h1>
        <ul className='list-disc space-y-4 mt-8'>
            <li>Policy Development and Implementation</li>
            <li>Strengthening Procurement Logistics and Supply Chain Management</li>
            <li>Corporate Social Responsibility</li>
            <li>Program Design & Implementation Support</li>
            <li>Capacity Building, Training and Development</li>
            <li>Project Management</li>
            <li>Monitoring and Evaluation & Assessments</li>
            <li>Advocacy & Communication</li>
        </ul>
        <p className='mt-12'>We focus our services to <span className='font-semibold'>Health Sector</span>(Primary & Secondary Healthcare Delivery, Immunization, Maternal Child Health, Child Nutrition, Malaria Control, HIV/AIDS Prevention, TB Control, Adolescent Girl Empowerment, etc.</p>
        <p>However, we have also successfully provided services to the Water & Sanitation and Primary Education sector.</p>
    </div>
  )
}

export default Services