import React from "react";
import { Link } from "react-router-dom";

const FooterLink = (props: { to: string }) => {
  return (
    <span>
      <Link
        to={`/${props.to.toLowerCase()}`}
        className="p-2 text-lime-600 text-md hover:underline"
      >
        {props.to}
      </Link>
    </span>
  );
};

export default FooterLink;
