import React from 'react'

const Capacity = () => {
  return (
    <div className='mt-32 mb-16 mx-16 flex flex-col space-y-8'>
        <p>i2i Consults team has a healthy mix of leading professionals from the fields of medicine, management, finance, statistics, data management, engineering, logistics, IT, procurement, and education. We also work with various International consultants to address specific needs of the projects.</p>
        <p>Our experience in both development sector as well as corporate sector provides unique advantage of applying the best practices of both sectors in the projects we undertake.</p>
        <p>Some of the leading development sector organizations our consultants have worked  with are DFID, USAID, World Bank, European Union, CIDA, WHO, UNICEF, Crown Agents, JSI, Abt Associates, Synergos Institute, and The Brooke.</p>
        <p>Some of the leading companies our consultants have worked with are Hindustan Unilever, Tata Group of companies, ICICI Bank, HDFC, Johnson and Johnson, Frito Lays, Pepsico, Tropicana, Cargill Foods, Coca Cola, Bank of America, Lehman Brothers, Apollo Tyres, etc.</p>
        <p>We have substantial experience working in a number of developing countries – India, Nigeria, East Timor, China, Mali, Sierra Leone, Kenya, Uganda, Ghana, Zimbabwe, Iraq and Botswana etc.</p>
    </div>
  )
}

export default Capacity