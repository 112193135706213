
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Projects from "./pages/Projects";
import Approach from "./pages/Approach";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Specialization from "./pages/Specialization";
import Advisory from "./pages/Advisory";
import Team from "./pages/Team";
import Clients from "./pages/Clients";
import OngoingProjects from "./pages/OngoingProjects";
import Partners from "./pages/Partners";
import Capacity from "./pages/Capacity";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="approach" element={<Approach />} />
        <Route path="services" element={<Services />} />
        <Route path="specialization" element={<Specialization />} />
        <Route path="projects" element={<Projects />} />
        <Route path="advisory" element={<Advisory />} />
        <Route path="team" element={<Team />} />
        <Route path="clients" element={<Clients />} />
        <Route path="ongoing" element={<OngoingProjects />} />
        <Route path="completed" element={<Projects />} />
        <Route path="partners" element={<Partners />} />
        <Route path="capacity" element={<Capacity />} />
        {/* <Route path="analytics" element={<Analytics />} /> */}

        <Route path="*" element={<div>Errors</div>} />
      </Route>
    </Routes>
  );
}

export default App;