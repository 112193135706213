import React from "react";
import { ClientListProps } from "../types";

const ClientList = (props: ClientListProps) => {
  const [showMore, setShowMore] = React.useState(false);
  return (
    <div className="grid grid-col-1 gap-2 justify-stretch">
      <h1 className="text-2xl font-bold tracking-wider">{props.title}</h1>
      <ul className="list-disc">
        {props.list.map((name, index) => {
          if (index < 4 || showMore) {
            return <li>{name}</li>;
          } else return undefined;
        })}

        {props.list.length > 4 && (
          <button
            className="text-lime-500 hover:underline"
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            {showMore ? "Show Less" : "Show More"}
          </button>
        )}
      </ul>
    </div>
  );
};

export default ClientList;
