import React from 'react'

type OngoingProjectProps = {
  projectName: string, 
  country: string,
  body: string, 
}

const projects: OngoingProjectProps[] = [
  {
    projectName: "PATH2 / DFID",
    country: "Nigeria",
    body: "This is £ 150 million project aimed towards improving sustainable availability of quality health services in selected states of Nigeria. i2i Consults is responsible for overall technical leadership and implementation of Logistic interventions for Health Commodities"
  },
  {
    projectName: "Global Fund/Nigeria Supply Chain Integration Project, Department of Food & Drugs, Federal Ministry of Health",
    country: "Nigeria",
    body: "This project aims at strengthening Federal & State Government coordination, Public Private Partnerships, and Integrated Logistics and Transportation including Last Mile Delivery. i2i Consults and its partner Health Systems Consult Limited (HSCL) are responsible for Finalization of the National Supply chain Policy, Development of a National Supply Chain Management Coordination Framework & implementation plan, Capacity building of NPSCMP and state Logistics Coordinating Units, and support for the development of State supply chain agencies and other issues of ownership and sustainability ."
  }
];

const OngoingProjectItem = (props:OngoingProjectProps) => {
  return (
    <div>
      <h2 className='text-lg text-lime-500 font-light'>{props.projectName} - <span className='font-semibold text-black font-semibold'>{props.country}</span></h2>
      <p>{props.body}</p>
    </div>
  )
}

const OngoingProjects = () => {
  return (
    <div className='mt-32 mx-16 flex flex-col space-y-8 mb-16'>
      {projects.map(element => 
            <OngoingProjectItem projectName={element.projectName} country={element.country} body={element.body} />
          )
        }
    </div>
  )
}

export default OngoingProjects