import React from "react";
import ClientList from "../components/ClientList";
import { ClientListProps } from "../types";

const clientList: ClientListProps[] = [
  {
    title: "Corporate Clients",
    list: [
      "Apollo Tyres",
      "Coca Cola India",
      "Hindustan Unilever",
      "Housing Development Finance Corporation (HDFC)",
      "ICICI Bank",
      "Johnson & Johnson",
      "Pepsi Foods India",
      "Taj Group of Hotels",
      "Tata Consultancy Services",
      "Tata Industries",
      "UNILEVER",
    ],
  },
  {
    title: "Government Clients",
    list: [
      "Agricultural Produce Market Committee, India",
      "Federal Ministry of Health, Nigeria",
      "Integrated Child Development Services, India",
      "Karnataka Health Systems Development Project (KHSDP), India",
      "Ministry of Health & Family Welfare (MoH&FW), India",
      "Namibian Alliance for Improved Nutrition (NAFIN), Namibia",
      "National Primary Health Care Development Agency (NPHCDA), Nigeria",
      "Uttaranchal Health Systems Development Project (UAHSDP), India",
      "Uttar Pradesh Health Systems Development Project (UPHSDP), India",
    ],
  },
  {
    title: "International Development /Donor Organisations",
    list: [
      "Abt Associates",
      "Asian Development Bank",
      "Department for International Development (DFID) / UKAID",
      "Global Fund to Fight AIDS, TB and Malaria",
      "JSI/Deliver",
      "Management Sciences for Health",
      "Nike Foundation",
      "Synergos Institute, New York",
      "The Elders",
      "The World Bank",
      "United Nations Children Fund (UNICEF)",
      "United Nations Development Program (UNDP)",
      "United States Agency for International Development (USAID)",
      "United Nations Office for Project Services (UNOPS)",
      "World Food Program (WFP)",
      "World Health Organisation (WHO)",
      "World Society for Protection of Animals (WSPA)",
    ],
  },
  {
    title: "NGOs",
    list: [
      "CARE India",
      "SALAAHkars",
      "SNEH",
      "The Brooke, India. Egypt, Jordan, Ethiopia & Pakistan",
      "The Brooke, UK",
    ],
  },
  {
    title: "Research & Academic Institutions",
    list: [
      "Administrative Staff College of India",
      "Delhi Society for Promotion of Rational Use of Drugs",
      "Institute for Socio Economic Change, India",
      "SIEMAT, UP",
    ],
  },
  {
    title: "Small & Medium Businesses",
    list: [
      "Sri Sri Scientific (Dairy & Agriculture) Farms",
      "Therapeutic Labs (Nigeria) Ltd",
    ],
  },
];

const Clients = () => {
  return (
    <div className="mt-28 pb-12 px-16 grid lg:grid-cols-2 grid-cols-1 gap-8 place-content-center">
      {clientList.map((clientListProps) => {
        return <ClientList {...clientListProps} />;
      })}
    </div>
  );
};

export default Clients;
