import React from 'react'

const Specialization = () => {
  return (
    <div className='mt-32 mx-16 mb-16'>
        <h1 className='text-3xl font-bold'>i2i Consults have proven expertise in:</h1>
        <ul className='list-disc space-y-8 mt-8'>
            <li>Strengthening governance, planning and budgeting for health commodities logistics.</li>
            <li>Improving policy, systems and processes for procurement and supply chain management of pharmaceuticals, vaccines, health commodities, equipment and related supplies.</li>
            <li>Designing & Executing innovative replicable & sustainable interventions for effective and efficient service delivery for the health, education and water supply & sanitation sectors.</li>
            <li>Building effective partnerships between multiple partners for synergizing core strengths to achieve compounded benefits.</li>
        </ul>
        <h1 className='text-3xl font-bold mt-8'>In the past we have been successfully involved in:</h1>
        <ul className='list-disc space-y-8 mt-8'>
            <li>Advocating</li>
            <li>Structuring</li>
            <li>Systemizing</li>
            <li>Facilitation for Required Resources</li>
            <li>Capacity Building</li>
            <li>Delivering</li>
            <li>Documenting</li>
            <li>Evaluation and Monitoring, for a wide range of development projects.</li>
        </ul>
    </div>
  )
}

export default Specialization;